import { useState } from "react";

export default function ScheduleMeetingForm(props) {
  const [showForm, setShowForm] = useState(false);
  const [initializedCalendly, setInitializedCalendly] = useState(false);

  const initCalendly = () => {
    let backgroundColor = 'ffffff';
    let textColor = '000224';
    let primaryColor = '0029FF';
    if ( window.location.pathname === '/b2b-website-audit' ) {
      backgroundColor = '000224';
      textColor = 'ffffff';
      primaryColor = '1A92D6';
    }

    Calendly.initInlineWidget({
        url: `https://calendly.com/conveydigital/meet-with-michael-from-convey?hide_landing_page_details=1&hide_event_type_details=1&background_color=${backgroundColor}&text_color=${textColor}&primary_color=${primaryColor}`,
        parentElement: document.getElementById(props.calendlyId),
        utm: {},
        resize: true
    });
  }

  const renderCalendlyForm = () => {
    if ( $("#calendlyScript").length === 0 ) {
      var calendlyScript = document.createElement('script');
      calendlyScript.id = "calendlyScript";
      calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js";
      document.head.appendChild(calendlyScript);
      calendlyScript.onload = initCalendly;
      setInitializedCalendly(true);

      document.querySelectorAll('.contact-us-form img[data-src]').forEach(function(img){img.src = img.dataset.src});

    } else if(!initializedCalendly) {
      initCalendly();
      setInitializedCalendly(true);
    }
  }

  const toggleShowForm = () => {
    renderCalendlyForm();
    setShowForm(!showForm);
  };

  return (
    <>
      <span onClick={toggleShowForm}>Schedule a free strategy session ›</span>
      <div className={`contact-us-form ${showForm ? 'd-block' : 'd-none'}`}>
        <button className="contact-us-form__close" onClick={toggleShowForm}>&times;</button>
        <div className="contact-us-form__inner">
          <div className="h4 text-center">
            <span style={{marginBottom:'10px', display:'inline-block'}}>Schedule a free strategy session with</span> <br />
            <img width="45" style={{position:'relative',top:"-5px"}} data-src="/images/about-us/team/michael-williamson-headshot.png" /> Michael, B2B Website Strategist
          </div>
          <hr />
          <div id={props.calendlyId}></div>
        </div>
      </div>
    </>
  );
}